<template>
	<div>
		<audio id="music" preload="auto" controls="controls" loop="loop" src="./music/heart.mp3"></audio>
		<audio id="music2" preload="auto" controls="controls" src="./music/开门.mp3"></audio>
		<audio id="music3" preload="auto" controls="controls" src="./music/关门.mp3"></audio>
		<div id="all" class="all" @mousedown="coordinate($event)" @click="door()">
			<div class="dibu" id="dibu"></div>
			<p class="textp" id="textp">一个小女孩来到这个黑色的世界，在远方有一扇门，要过去看看吗。。。</p>
			<p class="textz" id="textz"></p>
			<div id="door_all" class="door_all">
				<div id="door" class="door">
					<div class="door_open"></div>
				</div>
				<div id="door_box" class="door_box"></div>
				<div id="door_down" class="door_down"></div>
			</div>
			<div class="woman" id="woman">
				<div id="heas" class="head"></div>
				<div id="heas" class="heal">
					<div class="heal1"></div>
					<div class="heal2"></div>
					<div class="heal3"></div>
				</div>
			</div>
			<div id="texts" class="texts"></div>
		</div>
		<div class="start" id="start" @click="starts()">
			<p class="textpp" id="textpp">点击屏幕开始</p>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				num1:2,
				num2:0,
				num3:0,
				tnum:0,
				start1:null,
				start2:null,
				start3:null,
				start4:null,
				start5:null,
				start6:null,
				start7:null,
				start8:null,
				startss:null,
				url:'https://www.limengfei.top/',
				url2:'http://127.0.0.1:3000/',
			}
		},
		methods:{
			door(){
				switch (this.num1) {
					case 0:
						document.getElementById("music2").play();
						document.getElementById("textz").style.width = "400px";
						this.num1 = 3;
						document.getElementById("door").style.transform = "rotateY(-90deg)";
						document.getElementById("door_down").style.boxShadow = "0px 0px 1000px 500px #fff";
						document.getElementById("door_box").style.zIndex = "1";
						this.start8 = setInterval(this.ps1, 3000);
						break;
					case 1:
						document.getElementById("music3").play();
						document.getElementById("door").style.transform = "rotateY(0deg)";
						document.getElementById("door_down").style.boxShadow = "0px 0px 10px 0px white";
						document.getElementById("door").style.backgroundColor = "white";
						this.start1 = setInterval(this.closeDoor, 2000);
						var str = '';
						for (var i = 0; i < 8; i++) {
							str += '<div id="ts' + i +
								'" style="width:0px;height:0px;border:20px solid black;position:absolute;top:50%;left:50%;transition:2s;opacity:0;text-align:center;font-size:60px;line-height: 100vh;z-index: 1;"><b>' +
								(7 - i) + '</b></div>';
							document.getElementById("texts").innerHTML = str;
						}
						this.num1 = 4;
						this.start3 = setInterval(this.closeStart2, 5000);
						break;
					case 2:
						document.getElementById("textz").style.width = "400px";
						document.getElementById("door_all").style.top = "20%";
						document.getElementById("door_all").style.width = "20%";
						document.getElementById("door_all").style.height = "60%";
						this.num1 = 0;
						this.start7 = setInterval(this.ps, 3000);
						break;
					case 3:
						document.getElementById("textz").style.width = "400px";
						document.getElementById("textp").style.zIndex = "0";
						document.getElementById("woman").style.top = "60%";
						document.getElementById("woman").style.left = "50%";
						this.num1 = 1;
						this.start4 = setInterval(this.closedoor, 1000);
						break;
					case 4:
						break;
				}
			},
			ps(){
				document.getElementById("textz").style.width = "0px";
				document.getElementById("textp").innerHTML = "门的背后是未知，要...开门吗";
				clearInterval(this.start7);
			},
			ps1(){
				document.getElementById("textz").style.width = "0px";
				document.getElementById("textp").style.color = "black";
				document.getElementById("textp").style.zIndex = "2";
				document.getElementById("textp").innerHTML = "要...进去吗？也许是恐惧，也许是可怕的东西的在后面...";
				clearInterval(this.start8);
			},
			starts(){
				this.kaishi();
				document.getElementById("start").style.height = "0vh";
				document.getElementById("textpp").style.display = "none";
			},
			closedoor(){
				this.num3++;
				if (this.num3 == 3) {
					this.door();
					clearInterval(this.start4);
				}
			},
			closeDoor(){
				this.num2++;
				var body_w = document.getElementById("all").offsetWidth;
				var body_h = document.getElementById("all").offsetHeight;
				var b1_x = Math.ceil(document.getElementById("door_all").getBoundingClientRect().left);
				var b1_y = Math.ceil(document.getElementById("door_all").getBoundingClientRect().top);
				document.getElementById("door_all").style.width = "100%";
				document.getElementById("door_all").style.height = "200%";
				document.getElementById("door_all").style.left = "-1%";
				document.getElementById("door_all").style.top = "-50%";
				if (this.num2 == 2) {
					clearInterval(this.start1);
				}
			},
			texts(){
				if (this.start3 != null) {
					clearInterval(this.start3);
					this.start3 = null;
				}
				if (this.tnum != 0) {
					document.getElementById("ts" + (this.tnum - 1)).innerHTML = '';
				}
				if (this.tnum == 7) {
					document.getElementById("ts" + this.tnum).innerHTML =
						`<iframe id="frame" src= "${this.url}static/lover.html" frameborder="0" width="100%" height="100%"></iframe>`; //此处做修改就行了
				}
				document.getElementById("ts" + this.tnum).style.width = "99%";
				document.getElementById("ts" + this.tnum).style.height = "99%";
				document.getElementById("ts" + this.tnum).style.top = "-1%";
				document.getElementById("ts" + this.tnum).style.left = "-1%";
				document.getElementById("ts" + this.tnum).style.opacity = "1";
				this.tnum++;
				if (this.tnum == 8) {
					document.getElementById("ts7").style.borderWidth = "0px";
					document.getElementById("ts7").style.width = "101%";
					document.getElementById("ts7").style.height = "102%";
					clearInterval(this.start2);
					document.getElementById("music").pause();
					document.getElementById("music").src=`${this.url}static/music/piano.mp3`;
					document.getElementById("music").play();
				}
			},
			closeStart2(){
				this.start2 = setInterval(this.texts, 1000);
			},
			kaishi(){
				document.getElementById("music").play();
				var docElm = document.documentElement;
				//W3C   
				if (docElm.requestFullscreen) {
					docElm.requestFullscreen();
				}
				//FireFox   
				else if (docElm.mozRequestFullScreen) {
					docElm.mozRequestFullScreen();
				}
				//Chrome等   
				else if (docElm.webkitRequestFullScreen) {
					docElm.webkitRequestFullScreen();
				}
				//IE11   
				else if (elem.msRequestFullscreen) {
					elem.msRequestFullscreen();
				}
			},
			coordinate(event){
				this.startss = setInterval(this.clickxgt);
				var x = event.clientX;
				var y = event.clientY;
				document.getElementById("dibu").innerHTML = "<div class='ys' id='ys' style='top: " + y + "px;left: " + x +
					"px;'></div>";
			},
			clickxgt(){
				clearInterval(this.startss);
				document.getElementById("ys").style.width = "100px";
				document.getElementById("ys").style.height = "100px";
				document.getElementById("ys").style.top = document.getElementById("ys").offsetTop - 50 + "px";
				document.getElementById("ys").style.left = document.getElementById("ys").offsetLeft - 50 + "px";
				document.getElementById("ys").style.opacity = "0";
			}
		}
	}
</script>

<style scoped="scoped">
	* {
		padding: 0px;
		margin: 0px;
		list-style-type: none;
		font-family: "方正舒体";
	}
	
	.ys {
		width: 1px;
		height: 1px;
		background-color: transparent;
		border: 2px solid white;
		border-radius: 50%;
		position: absolute;
		transition: all 1s linear;
		z-index: 2;
		box-shadow: 0 0 100px 10px white;
	}
	
	.all {
		width: 100%;
		height: 100vh;
		background-color: #000000;
		perspective: 800px;
		overflow: hidden;
	}
	
	.door_all {
		width: 5%;
		height: 15%;
		position: absolute;
		top: 5%;
		left: 40%;
		transition: 5s;
	}
	
	.door {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: black;
		z-index: 1;
		border: 5px solid #550000;
		transition: 2s;
		transform-origin: left;
		box-shadow: 0 0 0 0 white;
	}
	
	.door_down {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: white;
		border: 5px solid #550000;
		box-shadow: 0px 0px 10px 0px #fff;
		transition: 2s;
	}
	
	.door_box {
		border: 5px solid #000000;
		width: 100%;
		height: 100%;
		transform: rotateX(-95deg);
		transform-origin: bottom;
		background-color: #000000;
		transition: 2s;
		position: absolute;
		opacity: 0.2;
	}
	
	.door_open {
		width: 3%;
		height: 8%;
		background-color: white;
		border-radius: 1em;
		position: relative;
		top: 45%;
		left: 90%;
		backface-visibility: hidden;
	}
	
	.woman {
		position: absolute;
		top: 85%;
		left: 60%;
		z-index: 1;
		perspective: 1000px;
		transition: 4s;
	}
	
	.head {
		width: 30px;
		height: 30px;
		border-radius: 12em;
		background-color: white;
		border: 1px solid white;
	}
	
	.heal div {
		width: 30px;
		height: 40px;
		background-color: white;
		position: absolute;
		border: 1px solid white;
	}
	
	.heal2 {
		transform-origin: right top;
		transform: rotateZ(-20deg);
	}
	
	.heal3 {
		transform-origin: left top;
		transform: rotateZ(20deg);
	}
	
	.texts {
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	
	.textp {
		color: white;
		position: absolute;
		top: 20%;
		left: 80%;
		font-size: 25px;
		text-shadow: 5px 2px 10px white;
	}
	
	.textz {
		width: 0px;
		height: 110px;
		background-color: #000000;
		position: absolute;
		top: 20%;
		left: 75%;
		transition: all 3s linear;
	}
	
	.start {
		width: 100%;
		height: 100vh;
		background-color: #000000;
		position: absolute;
		top: 0px;
		transition: all 3s ease-in;
	}
	
	.textpp {
		color: white;
		font-size: 40px;
		text-shadow: 5px 2px 10px white;
		text-align: center;
	}
	.dibu{
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	audio{
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		display: none;
	}
</style>
